@use 'variables' as v;

.primary-color {
    color:v.$primary_color;
}

.abt-img-header {
    width: 534px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.abt-text-style {
    color: black;
    letter-spacing: 0px;
    text-shadow: 0px 4px 3px #C4C4C4;
    // line-height: 12px;
}

// .card-opacity {
//     background: rgba(0,0,0,0.5);
// }

.card-opacity {
    height: 100%;
    position: absolute; /* Make the parent element position: relative */
    bottom: -100%;
  }
  
  .card-opacity::before {
    content: ""; /* Create a pseudo-element */
    position: absolute; /* Position the pseudo-element absolutely */
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    }
  
  .avoid-opacity {
    position: relative; /* Make the child element position: relative */
    z-index: 1;
  }

