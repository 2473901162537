@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --pikkat-white: #fbfbfb;
  --pikkat-white-2: #f4f4f4;
  --pikkat-black: #272424;
  --pikkat-green: #29AA43;
  --pikkat-gray: #373B38;
  --pikkat-gray-2: #6F6F6F;
  --pikkat-red: #e62129;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  background-color: var(--pikkat-red);
  height: 24px;
  color: #fbfbfb;
  font-size: 18px;
  padding-inline: 12px;
  border-radius: 8px;
}

.submit-button {
  background-color: var(--pikkat-green);
  width: 130px;
  padding: 15px;
  color: var(--pikkat-white);
  border-radius: 20px;

}

.benefits-card {
  height: 375px;
  width: 285px;
  padding: 12px;
  background-color: #fbfbfb;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1em;
  border-width: 2px;
  border-color: transparent;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 3px;
  transition: transform 0.3s ease, border-width 0.3s ease,
    border-radius 0.3s ease, border-color 0.3s ease;
}

/* Small screens */
@media (max-width: 768px) {
  .benefits-card {
    height: 340px;
  }
}

.min-h-40 {
  min-height: 40px;
}
.benefits-card:hover {
  border-width: 2px;
  border-radius: 15px;
  border-color: #29aa43 !important;
  transform: scale(105%);
}

.benefits-card h2 {
  font-size: x-large;
  font-weight: bold;
}

.selected {
  background-color: var(--pikkat-red);
  text-wrap: wrap;
}

.not-selected {
  background-color: green;
  text-wrap: wrap;
}

.read-more {
  color: #00a2e9;
  text-decoration-line: underline;
  cursor: pointer;
}

.faq-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.faq-enter-active {
  max-height: 200px; /* Adjust as needed */
  opacity: 1;
}

.faq-exit {
  max-height: 200px; /* Adjust as needed */
  opacity: 1;
  overflow: hidden;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.faq-exit-active {
  max-height: 0;
  opacity: 0;
}

.light-text {
  color: var(--pikkat-gray-2);
  opacity: 80%;
}
@layer components {
  .primary-gradient {
    @apply bg-gradient-to-br from-pikkat-green/10 via-pikkat-green/10 to-white;
  }
}

.faq-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}

.faq-enter-active {
  max-height: 200px; /* Adjust based on the expected height */
  opacity: 1;
}

.faq-exit {
  max-height: 200px; /* Match this with enter-active height */
  opacity: 1;
  overflow: hidden;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.faq-exit-active {
  max-height: 0;
  opacity: 0;
}

.article {
  text-align: justify;
}

.article h1, h3{
  color: var(--pikkat-black);
  font-weight: 600;
}

.article h1 {
  font-size: 32px;
  padding: 24px 0px 12px 0px;
}

.article h3 {
  font-size: 20px;
}

.article p{
  padding-top: 1em;
  padding-bottom: 1em;
  color: var(--pikkat-gray);
}

.article img{
  padding: 1em 0em 1em 0em
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}