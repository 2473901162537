.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.custom-prev {
  left: 10px;
}

.custom-next {
  right: 10px;
}

.custom-prev:hover,
.custom-next:hover {
  background-color: var(--pikkat-green);
  opacity: 60%;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 0, 0, 0.5);
  opacity: 1; /* Ensure bullets are fully opaque */
  transition: background-color 0.3s;
}

.swiper-pagination-bullet-active {
  background-color: rgba(0, 255, 0, 0.8); /* Active bullet color */
}
